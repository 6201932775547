import { Box, Grid, Link as MuiLink, Typography } from "@material-ui/core";
import Link from "next/link";
import React from "react";
import { WindowHelpers } from "src/helpers";
import Routes from "src/routes";
import SEO from "../components/SEO";

const NotFound: React.FC = () => {
  return (
    <>
      <SEO title="404: Not found" noIndex />

      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="h1">Not Found</Typography>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography>
              That page doesn&apos;t seem to exist!
              {WindowHelpers.isEmbeddedView() ? "" : " Maybe try one of these?"}
            </Typography>
          </Box>
        </Grid>

        {!WindowHelpers.isEmbeddedView() && (
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Link href={Routes.Index} passHref>
                <MuiLink>
                  <Typography>Home</Typography>
                </MuiLink>
              </Link>
            </Grid>
            <Grid item>
              <Link href={Routes.Shop} passHref>
                <MuiLink>
                  <Typography>Shop</Typography>
                </MuiLink>
              </Link>
            </Grid>
            <Grid item>
              <Link href={Routes.Faqs} passHref>
                <MuiLink>
                  <Typography>FAQ</Typography>
                </MuiLink>
              </Link>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default NotFound;
