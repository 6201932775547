import { Box, Card, CardContent, Grid } from "@material-ui/core";
import Image from "next/image";
import BackgroundBanner from "public/images/the-void.png";
import React from "react";
import { WindowHelpers } from "src/helpers";
import BrowserLayout from "./BrowserLayout";

/** Used for _error and 404 to render a nice container for the error pages. */
const PageError: React.FC = ({ children }) => {
  return (
    <Box
      position="relative"
      height={WindowHelpers.isEmbeddedView() ? "100vh" : "70vh"}
    >
      <Image
        src={BackgroundBanner}
        alt="The Void"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        quality={100}
        priority
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <Grid item>
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

/** Include the BrowserLayout if we're not in an embedded view. */
export const ErrorLayout: React.FC = ({ children }) => {
  if (WindowHelpers.isEmbeddedView()) return <PageError>{children}</PageError>;
  return (
    <BrowserLayout>
      <PageError>{children}</PageError>
    </BrowserLayout>
  );
};
